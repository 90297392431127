import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\deepesh\\Documents\\project_2024\\alex-elite-luxury\\eliteluxurylimo\\frontend\\components\\Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\deepesh\\Documents\\project_2024\\alex-elite-luxury\\eliteluxurylimo\\frontend\\components\\Header.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\deepesh\\Documents\\project_2024\\alex-elite-luxury\\eliteluxurylimo\\frontend\\node_modules\\next\\font\\google\\target.css?{\"path\":\"app\\\\layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "C:\\Users\\deepesh\\Documents\\project_2024\\alex-elite-luxury\\eliteluxurylimo\\frontend\\app\\globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "C:\\Users\\deepesh\\Documents\\project_2024\\alex-elite-luxury\\eliteluxurylimo\\frontend\\node_modules\\react-hot-toast\\dist\\index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\deepesh\\Documents\\project_2024\\alex-elite-luxury\\eliteluxurylimo\\frontend\\providers\\index.tsx");
